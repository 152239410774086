.sponsor-section {
  &__all-sponsor {
    @media screen and (min-width: 600px) and (max-width: 959px) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: 599px) {
      justify-content: space-between;
    }

    & > div.MuiGrid-container > div.single-sponsor-logo {
      @media screen and (max-width: 959px) {
        padding: 1rem 0;
      }
    }
  }
}
