textarea.textarea {
  padding-top: 1rem;
  padding-left: 1rem;
  border-radius: 4px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  width: calc(100% - 1rem - 3px);
}

textarea::-webkit-input-placeholder {
  color: var(--white-color);
  opacity: 1;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--white-color);
  opacity: 1;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--white-color);
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: var(--white-color);
  opacity: 1;
}

textarea::placeholder {
  color: var(--white-color);
  opacity: 1;
}
