.heading {
  @media screen and (max-width: 959px) {
    text-align: center;
  }

  &__title {
  }
  &__subtitle {
    margin-top: 30px;

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }

    &--more-margin {
      margin-top: 50px;
    }
  }

  &--center {
    text-align: center;
  }
  &--margin-top {
    margin-top: 30px;
  }

  // text's color
  &--white {
    color: var(--white-color);
  }
}
