@import "../../../../Core/scss/mixins";

.single-card-offer {
  text-align: center;
  padding: 15% 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  @include transition(0.3s);

  &:hover {
    @media screen and (min-width: 600px) {
      box-shadow: 0 20px 40px -40px #000;
      transform: translateY(-30px);
      @include transition(0.3s);
    }
  }

  @media screen and (max-width: 960px) {
    padding: 9% 50px;
    margin-bottom: 50px;
  }

  &__image {
    margin: 0 auto;
    margin-bottom: 50px;
  }
}
