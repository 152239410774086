.button-standard {
  padding: 0.8rem 1.5rem;
  border: 0;

  &:hover {
    cursor: pointer;
  }

  &__flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.button-centered {
  display: block;
  margin: 0 auto;
}

.button-margin-top {
  margin-top: 50px;
}

.icon-button {
  height: 1rem;
  margin-left: 1rem;
  display: block;

  &--send {
    @extend .icon-button;
    height: 1.2rem;
    width: 1.2rem;
  }
}

.button-style {
  &--on-dark-background {
    @extend .button-margin-top;
    background-color: transparent;
    border: 3px solid var(--white-color);
    border-radius: 2rem;
    color: var(--white-color);
  }
}
