.time-tables-section {
  &__times-info {
    @media screen and (max-width: 959px) {
      margin-top: 50px;
    }
  }
  &__list {
    @media screen and (max-width: 959px) {
      display: inline-block;
      transform: translateX(-50%);
      margin-left: 50%;
      margin-top: 20px;
      min-width: 278px;
    }
  }
}
