.container-page {
  @media screen and (mix-width: 600px) {
    height: 100vh;
  }

  // change title size only for specific mobile versions (<= samsung S5 and )
  .heading__title {
    @media screen and (max-width: 360px) and (max-height: 640px) {
      font-size: 1.563rem;
    }
  }
  // giving background color to the section LinkServices (without the support of ObliqueBackground)
  .container-for-background {
    @media screen and (max-width: 500px) {
      background-color: var(--primary-color);
    }
  }
  // for mobile version ObliqueBackground it too problematic to handle (distance from 404 image and footer)
  .oblique-background {
    @media screen and (max-width: 500px) {
      opacity: 0;
    }
  }
}

.icon-error-404 {
  display: block;
  height: 220px;
  margin: auto;
  padding-top: 160px;

  @media screen and (max-width: 500px) {
    height: 120px;
    padding-top: 120px;
  }

  & > ellipse {
    animation: moving-eyes 2.5s infinite forwards linear;
  }
}

// The key of this animation is to make the eyes move quickly and at the same time, create some sort of delay
@keyframes moving-eyes {
  0% {
    transform: translateX(-2px);
  }
  49% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  99% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(-2px);
  }
}
