.footer {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-bottom: 0;

  &__copyright {
    @media screen and (min-width: 960px) {
      display: flex;
      align-items: center;
    }
  }
  &__contact-info {
    @media screen and (max-width: 959px) {
      margin-top: 50px;
    }
  }
}
