.custom-dialog div.MuiPaper-root {
  @media screen and (min-width: 600px) {
    padding: 50px;
    padding-top: 0;
  }
}

.custom-dialog button.MuiIconButton-root {
  width: 50px;
  left: 100%;

  @media screen and (max-width: 599px) {
    left: calc(100% - 50px);
  }
}

// These rules regolate the dimension of the text inside the dialog.
// In this way the image of the person can be kept to normal (perfect square).
.custom-dialog .heading__title {
  @media screen and (max-width: 599px) {
    margin-top: -10px;
  }
}

.custom-dialog p.heading__subtitle {
  @media screen and (max-width: 599px) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    margin-top: 20px;
  }
}
