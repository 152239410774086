@import "../../../Core/scss/mixins";

.left-appear,
.left-enter {
  left: 100%;
  opacity: 0;
}

.left-appear-active,
.left-enter.left-enter-active {
  left: 0;
  opacity: 1;
  transition: left 300ms linear;
}

.left-exit {
  left: 0;
  opacity: 0;
}

.left-exit.left-exit-active {
  left: 100%;
  opacity: 1;
  transition: left 300ms linear;
}

.mobile-header {
  height: calc(100vh - 60px);
  padding-top: 60px;
  position: fixed;
  width: 100%;
  z-index: 3;
  //left: 100%;
  display: none;
  background-color: var(--secondary-color);
  //@include transition(0.3s);

  @media screen and (min-width: 960px) {
    display: none;
  }

  &--open {
    @extend .mobile-header;
    //left: 0;
    display: block;
    //@include transition(0.3s);
  }
  &__list-voices {
    position: relative;
    width: 161px;
    padding-top: 2rem;
    left: calc(100% - 15px - 161px - 50px);

    .header-single-voice {
      display: inline-block;
      list-style: none;
      position: relative;
      width: 161px;
      padding-left: 15px;
      padding-right: 50px;
      padding-top: 2rem;
      color: var(--white-color);

      &--selected {
        @extend .header-single-voice;
        border-bottom: 3px solid var(--white-color);
      }

      .heading__title {
        text-align: left;
        @media screen and (max-width: 959px) {
          font-size: 1.563rem;
        }
      }
    }
  }
}
.mobile-header-open {
  overflow: hidden;
}
