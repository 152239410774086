.header {
  background-color: var(--secondary-color);
  position: fixed;
  width: 100%;
  z-index: 50;

  &__logo-section {
    display: flex;
    align-items: center;

    @media screen and (min-width: 960px) {
      justify-content: space-evenly;
    }

    &__name-company {
      color: var(--white-color);

      @media screen and (max-width: 1650px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 340px) {
        font-size: 1rem;
      }
    }
  }
  &__container-list-voices {
    @media screen and (max-width: 959px) {
      display: none;
    }
  }
  &__list-voices {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 80px;
  }
  &__container-hamburger-icon {
    min-height: 80px;
    display: flex;
    align-items: center;
    @media screen and (min-width: 960px) {
      display: none;
    }
    @media screen and (max-width: 599px) {
      min-height: 60px;
    }
  }
  &__hamburger-menu-icon {
    height: 2rem;
  }
}
