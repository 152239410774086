.base-image-style {
  max-height: 250px;
  display: block;
  object-fit: cover;
}

.image-center {
  margin: 0 auto;
}

.header-logo-image {
  height: 80px;
  width: 80px;

  @media screen and (max-width: 599px) {
    height: 60px;
    width: 60px;
  }
}

.sponsor-image {
  /* max-width: 200px; */

  @media screen and (max-width: 959px) {
    /* width: 150px; */
  }
}

.offer-sponsor {
  max-height: 450px;
  max-width: 100%;
  margin: auto;
}

.image-google-maps {
  max-width: 100%;

  @media screen and (min-width: 1280px) {
    max-height: 350px;
  }
  @media screen and (min-width: 960px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 959px) {
    margin: 0 auto;
    margin-top: 20px;
  }
}

.side-image {
  max-width: 100%;
  box-shadow: 0px 20px 30px -28px #000;
  border-radius: 4px;
  height: 100%;
  max-height: 352px;

  @media screen and (max-width: 959px) {
    margin: 0 auto;
    margin-top: 50px;
  }
}

.image-rounded {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;

  @media screen and (max-width: 599px) {
    width: 150px;
    height: 150px;
  }

  &--special {
    @extend .image-rounded;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 599px) {
      width: 200px;
      height: 200px;
    }
  }
}
