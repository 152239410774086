$margin-top-value: 20px;
$margin-top-value-reduced: 10px;

.paragraph {
  @media screen and (max-width: 959px) {
    text-align: center;
  }

  &--color-white {
    @extend .paragraph;
    color: var(--white-color);
  }
  &--standard {
    @extend .paragraph;
    &--margin {
      &--standard {
        @extend .paragraph--standard;
        margin-top: $margin-top-value;
      }
      &--reduced {
        @extend .paragraph--standard;
        margin-top: $margin-top-value-reduced;
      }
    }
  }
  &--bold {
    @extend .paragraph;
    display: block;
    &--margin {
      &--standard {
        @extend .paragraph--bold;
        margin-top: $margin-top-value;
      }
      &--reduced {
        @extend .paragraph--bold;
        margin-top: $margin-top-value-reduced;
      }
    }
  }
}

.error-message-email {
  padding-top: 10px;
  padding-left: 1rem;

  &--show {
    @extend .error-message-email;
    display: block;
  }
  &--hide {
    @extend .error-message-email;
    display: none;
  }
}
.error-message-textarea {
  padding-bottom: 10px;
  padding-left: 1rem;

  &--show {
    @extend .error-message-textarea;
    display: block;
  }
  &--hide {
    @extend .error-message-textarea;
    display: none;
  }
}
