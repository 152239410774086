.container-for-background {
  position: relative;

  .cover-background {
    object-fit: cover;
  }

  .backgr-standard {
    position: absolute;
    // max-height: 100%; is to overwrite the max-height: 250px; of base-image-style class of Image component
    max-height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &--gray {
      @extend .backgr-standard;
      background-color: var(--border-color);
    }
  }

  .oblique-background {
    background-color: var(--primary-color);
    position: absolute;
    z-index: -1;
    left: 0;
    top: -10%;
    bottom: -10%;
    width: 100%;
    transform: skewY(-3deg);
  }
}
