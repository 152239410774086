@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  /* COLORS */
  --primary-color: #4286f4;
  --secondary-color: #11346c;
  --border-color: #e4e5e9;
  --white-color: #fff;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
bold,
button {
  font-weight: 700;
}

/* Heading and Paragraph DIMENSIONS */

h1 {
  font-size: 3.052rem;
}
h2 {
  font-size: 2.441rem;
}
h3 {
  font-size: 1.953rem;
}
h4 {
  font-size: 1.563rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: var(--white-color);
}

button:hover {
  cursor: pointer;
}

section,
.footer-max-width {
  max-width: 90%;
  margin: auto;
}
section {
  padding: 50px 0;
  margin: 100px auto;
}
/* Eliminate space from top of the page (the margin would affect the header too) */
section.motto-section,
section.sponsor-section,
section.services-section,
section.team-section {
  margin: 0 auto;
}
/* Regolate the height of these first sections */
section.services-section,
section.team-section {
  padding-top: 100px;
}

.container-content {
  padding-top: 100px;
}
.container-content--sponsor {
  padding-top: 50px;
}

/* For inputs Material UI */
.MuiTextField-root,
textarea {
  width: 100%;
}
.MuiInputBase-input,
label {
  color: var(--white-color) !important;
}
/* Input's borders */
.MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--white-color) !important;
}
/* This eliminates the layer of border that is added */
.MuiOutlinedInput-root:hover {
  border: 0;
}
/* Handle justify-content CSS property for responsive */
.MuiGrid-container {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
/* Change Alert progress color (sending email) */
div.MuiAlert-filledInfo {
  background-color: #292929;
}

@media screen and (max-width: 959px) {
  textarea {
    margin-top: 30px;
  }
  .MuiGrid-container {
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media screen and (max-width: 599px) {
  h2 {
    font-size: 1.953rem;
  }
  h3 {
    font-size: 1.563rem;
  }
  p,
  span {
    font-size: 0.875rem;
  }
  .container-content {
    padding-top: 30px;
  }
  .distance-mobile {
    margin: 30px auto;
  }
}

.base-image-style{max-height:250px;display:block;object-fit:cover}.image-center{margin:0 auto}.header-logo-image{height:80px;width:80px}@media screen and (max-width: 599px){.header-logo-image{height:60px;width:60px}}.offer-sponsor{max-height:450px;max-width:100%;margin:auto}.image-google-maps{max-width:100%}@media screen and (min-width: 1280px){.image-google-maps{max-height:350px}}@media screen and (min-width: 960px){.image-google-maps{margin-top:30px}}@media screen and (max-width: 959px){.image-google-maps{margin:0 auto;margin-top:20px}}.side-image{max-width:100%;box-shadow:0px 20px 30px -28px #000;border-radius:4px;height:100%;max-height:352px}@media screen and (max-width: 959px){.side-image{margin:0 auto;margin-top:50px}}.image-rounded,.image-rounded--special{width:200px;height:200px;border-radius:50%;margin:auto}@media screen and (max-width: 599px){.image-rounded,.image-rounded--special{width:150px;height:150px}}.image-rounded--special{width:250px;height:250px}@media screen and (max-width: 599px){.image-rounded--special{width:200px;height:200px}}

@media screen and (max-width: 959px){.paragraph,.paragraph--color-white,.paragraph--standard,.paragraph--standard--margin--standard,.paragraph--standard--margin--reduced,.paragraph--bold,.paragraph--bold--margin--standard,.paragraph--bold--margin--reduced{text-align:center}}.paragraph--color-white{color:var(--white-color)}.paragraph--standard--margin--standard{margin-top:20px}.paragraph--standard--margin--reduced{margin-top:10px}.paragraph--bold,.paragraph--bold--margin--standard,.paragraph--bold--margin--reduced{display:block}.paragraph--bold--margin--standard{margin-top:20px}.paragraph--bold--margin--reduced{margin-top:10px}.error-message-email,.error-message-email--show,.error-message-email--hide{padding-top:10px;padding-left:1rem}.error-message-email--show{display:block}.error-message-email--hide{display:none}.error-message-textarea,.error-message-textarea--show,.error-message-textarea--hide{padding-bottom:10px;padding-left:1rem}.error-message-textarea--show{display:block}.error-message-textarea--hide{display:none}

@media screen and (max-width: 959px){.heading{text-align:center}}.heading__subtitle{margin-top:30px}@media screen and (max-width: 600px){.heading__subtitle{margin-top:20px}}.heading__subtitle--more-margin{margin-top:50px}.heading--center{text-align:center}.heading--margin-top{margin-top:30px}.heading--white{color:var(--white-color)}

.left-appear,.left-enter{left:100%;opacity:0}.left-appear-active,.left-enter.left-enter-active{left:0;opacity:1;transition:left 300ms linear}.left-exit{left:0;opacity:0}.left-exit.left-exit-active{left:100%;opacity:1;transition:left 300ms linear}.mobile-header,.mobile-header--open{height:calc(100vh - 60px);padding-top:60px;position:fixed;width:100%;z-index:3;display:none;background-color:var(--secondary-color)}@media screen and (min-width: 960px){.mobile-header,.mobile-header--open{display:none}}.mobile-header--open{display:block}.mobile-header__list-voices{position:relative;width:161px;padding-top:2rem;left:calc(100% - 15px - 161px - 50px)}.mobile-header__list-voices .header-single-voice,.mobile-header__list-voices .header-single-voice--selected{display:inline-block;list-style:none;position:relative;width:161px;padding-left:15px;padding-right:50px;padding-top:2rem;color:var(--white-color)}.mobile-header__list-voices .header-single-voice--selected{border-bottom:3px solid var(--white-color)}.mobile-header__list-voices .header-single-voice .heading__title,.mobile-header__list-voices .header-single-voice--selected .heading__title{text-align:left}@media screen and (max-width: 959px){.mobile-header__list-voices .header-single-voice .heading__title,.mobile-header__list-voices .header-single-voice--selected .heading__title{font-size:1.563rem}}.mobile-header-open{overflow:hidden}

.header{background-color:var(--secondary-color);position:fixed;width:100%;z-index:50}.header__logo-section{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}@media screen and (min-width: 960px){.header__logo-section{-webkit-justify-content:space-evenly;justify-content:space-evenly}}.header__logo-section__name-company{color:var(--white-color)}@media screen and (max-width: 1650px){.header__logo-section__name-company{margin-left:20px}}@media screen and (max-width: 340px){.header__logo-section__name-company{font-size:1rem}}@media screen and (max-width: 959px){.header__container-list-voices{display:none}}.header__list-voices{list-style:none;display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;-webkit-align-items:center;align-items:center;min-height:80px}.header__container-hamburger-icon{min-height:80px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}@media screen and (min-width: 960px){.header__container-hamburger-icon{display:none}}@media screen and (max-width: 599px){.header__container-hamburger-icon{min-height:60px}}.header__hamburger-menu-icon{height:2rem}

.footer{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;padding:1rem 0;background-color:var(--secondary-color);color:var(--white-color);margin-bottom:0}@media screen and (min-width: 960px){.footer__copyright{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}}@media screen and (max-width: 959px){.footer__contact-info{margin-top:50px}}

.container-for-background{position:relative}.container-for-background .cover-background{object-fit:cover}.container-for-background .backgr-standard,.container-for-background .backgr-standard--gray{position:absolute;max-height:100%;top:0;left:0;width:100%;height:100%;z-index:-1}.container-for-background .backgr-standard--gray{background-color:var(--border-color)}.container-for-background .oblique-background{background-color:var(--primary-color);position:absolute;z-index:-1;left:0;top:-10%;bottom:-10%;width:100%;-webkit-transform:skewY(-3deg);transform:skewY(-3deg)}

.motto-section{height:calc(100vh - 80px - 50px);padding-top:80px;position:relative}@media screen and (max-width: 599px){.motto-section{height:calc(100vh - 60px - 50px)}}.motto-section__container-text{display:inline-block;position:absolute;left:0;bottom:-1rem;-webkit-transform:skewY(-5deg);transform:skewY(-5deg);padding:3.052rem;background-color:var(--primary-color);color:var(--white-color)}@media screen and (max-width: 959px){.motto-section__container-text{padding:2.441rem}}@media screen and (max-width: 599px){.motto-section__container-text{padding:1.953rem;bottom:0}}@media screen and (max-width: 959px){.motto-section__container-text__motto-text{font-size:2.441rem}}@media screen and (max-width: 599px){.motto-section__container-text__motto-text{font-size:1.953rem}}

@media screen and (min-width: 600px) and (max-width: 959px){.sponsor-section__all-sponsor{-webkit-justify-content:space-evenly;justify-content:space-evenly}}@media screen and (max-width: 599px){.sponsor-section__all-sponsor{-webkit-justify-content:space-between;justify-content:space-between}}@media screen and (max-width: 959px){.sponsor-section__all-sponsor>div.MuiGrid-container>div.single-sponsor-logo{padding:1rem 0}}

.single-card-offer{text-align:center;padding:15% 20px;border:1px solid var(--border-color);border-radius:10px;transition:.3s;-moz-transition:.3s;-webkit-transition:.3s;-o-transition:.3s}@media screen and (min-width: 600px){.single-card-offer:hover{box-shadow:0 20px 40px -40px #000;-webkit-transform:translateY(-30px);transform:translateY(-30px);transition:.3s;-moz-transition:.3s;-webkit-transition:.3s;-o-transition:.3s}}@media screen and (max-width: 960px){.single-card-offer{padding:9% 50px;margin-bottom:50px}}.single-card-offer__image{margin:0 auto;margin-bottom:50px}


.button-standard{padding:0.8rem 1.5rem;border:0}.button-standard:hover{cursor:pointer}.button-standard__flex-container{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.button-centered{display:block;margin:0 auto}.button-margin-top,.button-style--on-dark-background{margin-top:50px}.icon-button,.icon-button--send{height:1rem;margin-left:1rem;display:block}.icon-button--send{height:1.2rem;width:1.2rem}.button-style--on-dark-background{background-color:transparent;border:3px solid var(--white-color);border-radius:2rem;color:var(--white-color)}

@media screen and (max-width: 599px){.text-image-container{padding-top:100px}}

section#cosa_facciamo>div.container-content>div.text-image-container:first-of-type{padding-top:30px}

.custom-input-margin{margin:30px 0}

textarea.textarea{padding-top:1rem;padding-left:1rem;border-radius:4px;background-color:transparent;border:2px solid #fff;color:#fff;width:calc(100% - 1rem - 3px)}textarea::-webkit-input-placeholder{color:var(--white-color);opacity:1}textarea:-ms-input-placeholder{color:var(--white-color);opacity:1}textarea::placeholder{color:var(--white-color);opacity:1}

.form-email-section div.container-grid-form{-webkit-justify-content:space-between;justify-content:space-between}@media screen and (max-width: 959px){.form-email-section div.container-grid-form{-webkit-justify-content:center;justify-content:center}}

.single-time-record{margin:10px 0;list-style:none}.single-time-record__day-week{display:inline-block;width:90px}

@media screen and (max-width: 959px){.time-tables-section__times-info{margin-top:50px}}@media screen and (max-width: 959px){.time-tables-section__list{display:inline-block;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-left:50%;margin-top:20px;min-width:278px}}

.MuiGrid-container{-webkit-justify-content:space-around;justify-content:space-around}.MuiDialog-paperScrollPaper{display:block !important}@media screen and (min-width: 960px){section.team-section div.MuiGrid-container:first-of-type>div.MuiGrid-item{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:end;align-items:end}}@media screen and (min-width: 960px){section.team-section div.MuiGrid-container:nth-of-type(n+2){padding-top:60px}}section.team-section>div.container-content>div.MuiGrid-container>div.MuiGrid-item{-webkit-align-items:flex-end;align-items:flex-end}.single-team-member{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}@media screen and (max-width: 959px){.single-team-member{padding:20px 0}}.single-team-member__sub-div{display:inline-block;text-align:center;position:relative}.single-team-member__sub-div__plus{background-color:var(--primary-color);position:absolute;height:1.5rem;width:1.5rem;font-size:50px;padding:0.5rem;border-radius:50px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;top:0;right:0;color:var(--white-color)}.single-team-member__sub-div__plus:hover{cursor:pointer}

@media screen and (min-width: 600px){.custom-dialog div.MuiPaper-root{padding:50px;padding-top:0}}.custom-dialog button.MuiIconButton-root{width:50px;left:100%}@media screen and (max-width: 599px){.custom-dialog button.MuiIconButton-root{left:calc(100% - 50px)}}@media screen and (max-width: 599px){.custom-dialog .heading__title{margin-top:-10px}}@media screen and (max-width: 599px){.custom-dialog p.heading__subtitle{max-width:80%;margin-left:auto;margin-right:auto;padding-bottom:30px;margin-top:20px}}

@media screen and (mix-width: 600px){.container-page{height:100vh}}@media screen and (max-width: 360px) and (max-height: 640px){.container-page .heading__title{font-size:1.563rem}}@media screen and (max-width: 500px){.container-page .container-for-background{background-color:var(--primary-color)}}@media screen and (max-width: 500px){.container-page .oblique-background{opacity:0}}.icon-error-404{display:block;height:220px;margin:auto;padding-top:160px}@media screen and (max-width: 500px){.icon-error-404{height:120px;padding-top:120px}}.icon-error-404>ellipse{-webkit-animation:moving-eyes 2.5s infinite forwards linear;animation:moving-eyes 2.5s infinite forwards linear}@-webkit-keyframes moving-eyes{0%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}49%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}50%{-webkit-transform:translateX(2px);transform:translateX(2px)}99%{-webkit-transform:translateX(2px);transform:translateX(2px)}100%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}}@keyframes moving-eyes{0%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}49%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}50%{-webkit-transform:translateX(2px);transform:translateX(2px)}99%{-webkit-transform:translateX(2px);transform:translateX(2px)}100%{-webkit-transform:translateX(-2px);transform:translateX(-2px)}}

.fade-appear,.fade-enter{opacity:0;z-index:1}.fade-appear-active,.fade-enter.fade-enter-active{opacity:1;transition:opacity 300ms linear}.fade-exit{opacity:1}.fade-exit.fade-exit-active{opacity:0.01;transition:opacity 300ms linear}

