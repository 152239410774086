@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  /* COLORS */
  --primary-color: #4286f4;
  --secondary-color: #11346c;
  --border-color: #e4e5e9;
  --white-color: #fff;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
bold,
button {
  font-weight: 700;
}

/* Heading and Paragraph DIMENSIONS */

h1 {
  font-size: 3.052rem;
}
h2 {
  font-size: 2.441rem;
}
h3 {
  font-size: 1.953rem;
}
h4 {
  font-size: 1.563rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: var(--white-color);
}

button:hover {
  cursor: pointer;
}

section,
.footer-max-width {
  max-width: 90%;
  margin: auto;
}
section {
  padding: 50px 0;
  margin: 100px auto;
}
/* Eliminate space from top of the page (the margin would affect the header too) */
section.motto-section,
section.sponsor-section,
section.services-section,
section.team-section {
  margin: 0 auto;
}
/* Regolate the height of these first sections */
section.services-section,
section.team-section {
  padding-top: 100px;
}

.container-content {
  padding-top: 100px;
}
.container-content--sponsor {
  padding-top: 50px;
}

/* For inputs Material UI */
.MuiTextField-root,
textarea {
  width: 100%;
}
.MuiInputBase-input,
label {
  color: var(--white-color) !important;
}
/* Input's borders */
.MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--white-color) !important;
}
/* This eliminates the layer of border that is added */
.MuiOutlinedInput-root:hover {
  border: 0;
}
/* Handle justify-content CSS property for responsive */
.MuiGrid-container {
  justify-content: space-between;
}
/* Change Alert progress color (sending email) */
div.MuiAlert-filledInfo {
  background-color: #292929;
}

@media screen and (max-width: 959px) {
  textarea {
    margin-top: 30px;
  }
  .MuiGrid-container {
    justify-content: center;
  }
}
@media screen and (max-width: 599px) {
  h2 {
    font-size: 1.953rem;
  }
  h3 {
    font-size: 1.563rem;
  }
  p,
  span {
    font-size: 0.875rem;
  }
  .container-content {
    padding-top: 30px;
  }
  .distance-mobile {
    margin: 30px auto;
  }
}
