.single-time-record {
  margin: 10px 0;
  list-style: none;

  &__day-week {
    display: inline-block;
    width: 90px;
  }
  &__time {
  }
}
