// change justify-content for the team members (after confrontation, space-between is too much for these elements)
.MuiGrid-container {
  justify-content: space-around;
}
// this was responsible for press the member's image inside the dialog
.MuiDialog-paperScrollPaper {
  display: block !important;
}

// keep all elements on first line on the same level of the bottom of the big one => >= 960px
section.team-section div.MuiGrid-container:first-of-type > div.MuiGrid-item {
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: end;
  }
}

// add some space between the first and the second line (because of the align-items: end) => >= 960px
section.team-section div.MuiGrid-container:nth-of-type(n + 2) {
  @media screen and (min-width: 960px) {
    padding-top: 60px;
  }
}

section.team-section
  > div.container-content
  > div.MuiGrid-container
  > div.MuiGrid-item {
  align-items: flex-end;
}

.single-team-member {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 959px) {
    padding: 20px 0;
  }

  &__sub-div {
    display: inline-block;
    text-align: center;
    position: relative;

    &__plus {
      background-color: var(--primary-color);
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      font-size: 50px;
      padding: 0.5rem;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      color: var(--white-color);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
