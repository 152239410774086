$font-size-desktop: 3.052rem;
$font-size-tablet: 2.441rem;
$font-size-mobile: 1.953rem;

.motto-section {
  height: calc(100vh - 80px - 50px);
  padding-top: 80px;
  position: relative;

  @media screen and (max-width: 599px) {
    height: calc(100vh - 60px - 50px);
  }

  &__container-text {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -1rem;
    transform: skewY(-5deg);
    padding: $font-size-desktop;
    background-color: var(--primary-color);
    color: var(--white-color);

    @media screen and (max-width: 959px) {
      padding: $font-size-tablet;
    }
    @media screen and (max-width: 599px) {
      padding: $font-size-mobile;
      bottom: 0;
    }

    &__motto-text {
      @media screen and (max-width: 959px) {
        font-size: $font-size-tablet;
      }
      @media screen and (max-width: 599px) {
        font-size: $font-size-mobile;
      }
    }
  }
}
